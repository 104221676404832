import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { passiveArg } from '../utils';
import classnames from 'classnames';

import * as css from './Cursor.module.css';

// These tags make the mouse resize
const scaleTo = 1.3;
const resizeTags = ['a', 'button'];

const Cursor = () => {
  const [hidden, setHidden] = useState(false);

  const [{ x, y, scale }, animation] = useSpring(
    () => ({
      x: 0,
      y: 0,
      scale: 1,
      immediate: true
    }),
    []
  );

  useEffect(() => {
    const menu = document.getElementById('menu');
    const bounds = menu ? menu.getBoundingClientRect() : null;

    const onMouseLeave = () => {
      setHidden(true);
    };

    const onMouseEnter = () => {
      setHidden(false);
    };

    const onMouseMove = e => {
      if (
        bounds &&
        e.clientX > bounds.x - 1 &&
        e.clientX < bounds.x + 30 &&
        e.clientY < bounds.y + 30 &&
        e.clientY > bounds.y - 1
      ) {
        animation.set({
          x: bounds.x,
          y: bounds.y,
          scale: scaleTo
        });
      } else {
        animation.set({
          x: e.clientX - 10,
          y: e.clientY - 10
        });
      }
    };

    const onMouseOver = e => {
      if (resizeTags.includes(e.target.nodeName.toLowerCase())) {
        animation.set({ scale: scaleTo });
      }
    };

    const onMouseOut = e => {
      animation.set({ scale: 1 });
    };

    document.addEventListener('mousemove', onMouseMove, passiveArg);
    document.addEventListener('mouseenter', onMouseEnter, passiveArg);
    document.addEventListener('mouseleave', onMouseLeave, passiveArg);
    document.addEventListener('mouseover', onMouseOver, passiveArg);
    document.addEventListener('mouseout', onMouseOut, passiveArg);

    return () => {
      document.removeEventListener('mousemove', onMouseMove, passiveArg);
      document.removeEventListener('mouseenter', onMouseEnter, passiveArg);
      document.removeEventListener('mouseleave', onMouseLeave, passiveArg);
      document.removeEventListener('mouseover', onMouseOver, passiveArg);
      document.removeEventListener('mouseout', onMouseOut, passiveArg);
    };
  }, [animation]);

  return (
    <animated.div
      className={classnames(css.outer, {
        [css.hidden]: hidden
      })}
      style={{ x, y }}
    >
      <animated.div
        className={css.inner}
        style={{
          scale,
          backgroundColor: scale.to(s =>
            s === 1 ? 'var(--slate)' : 'var(--blush)'
          )
        }}
      />
    </animated.div>
  );
};

export default Cursor;
