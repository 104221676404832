import React from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';

import 'sanitize.css';
import '../styles/variables.css';
import '../styles/base.css';
import '../styles/fonts.css';
import * as css from './Layout.module.css';

const Layout = ({ children, className }) => {
  return (
    <div className={classnames(css.root, className)}>
      <Helmet>
        <title>
          Round – A human-first and purpose-driven top-tier strategy consulting
          company
        </title>
        <meta
          name="author"
          content="Design Systems International, i@designsystems.international"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" sizes="32x32" />
      </Helmet>
      <main>{children}</main>
    </div>
  );
};

export default Layout;
