import { supportsPassiveEvents } from 'detect-it';

/**
 * This can be passed as the third argument to addEventListener to achieve passive listeners
 */
export const passiveArg = supportsPassiveEvents ? { passive: true } : false;
export const activeArg = supportsPassiveEvents ? { passive: false } : true;

/**
 * Returns the size of the window as [width, height]
 */
export const getWindowSize = () => {
  if (typeof window === 'object') {
    return [
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    ];
  } else {
    return [null, null];
  }
};

/**
 * Returns the height of the content area
 */
export const getDocumentHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );

/**
 * Maps on range of values to another range of values
 */
export const map = (n, start1, stop1, start2, stop2, ease) => {
  let prog = Math.min(1, Math.max(0, (n - start1) / (stop1 - start1)));
  if (ease) {
    prog = ease(prog);
  }
  return prog * (stop2 - start2) + start2;
};

/**
 * Provides a 0-1 value based on the element's visibility in the window
 */
export const visibleProgress = (top, height, winHeight) =>
  map(top, winHeight, -height, 0, 1);

/**
 * Provides a 0-1 value based on the element's visibility when fully covering the window
 */
export const coverProgress = (top, height, winHeight) =>
  map(top, 0, -(height - winHeight), 0, 1);

/**
 * Generates a random number between min and max (max not included)
 */
export const randomBetween = (min, max) => Math.random() * (max - min) + min;

/**
 * Gets a random values from an array
 */
export const randomFromArray = arr =>
  arr[Math.floor(Math.random() * arr.length)];

/**
 * Convert degrees to radians
 */
export const radians = degrees => degrees * (Math.PI / 180);
